"use strict";

jQuery(document).ready(function (jQuery) {
  jQuery(document).foundation();
  var nav = jQuery(".header-mega-menu");
  $('.open-menu').on('click', function () {
    var link = $(this);
    $('.header-mega-menu').slideToggle('slow', function () {
      if ($(this).is(':visible')) {
        var labelClose = link.find('.menu-label').data('label-close');
        link.find('.menu-label').text(labelClose);
        link.find('.nav-icon').addClass('open');
        $('.header-search.is-desktop').fadeOut();
      } else {
        var labelOpen = link.find('.menu-label').data('label-open');
        link.find('.menu-label').text(labelOpen);
        link.find('.nav-icon').removeClass('open');
        $('.header-search.is-desktop').fadeIn();
      }
    });
  });
  function isScrolledIntoView(elem) {
    var docViewTop = jQuery(window).scrollTop();
    var docViewBottom = docViewTop + (jQuery(window).height() + 200); // 200px offset for earlier response

    var elemTop = jQuery(elem).offset().top;
    var elemBottom = elemTop + jQuery(elem).height();
    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }
  var ul = jQuery("nav#site-navigation ul li ul");
  var li = jQuery("nav#site-navigation ul li ul li");
  if (ul.hasClass("children")) {
    ul.parent(li).addClass("hasChildren");
  }
  if (li.hasClass("hasChildren")) {
    jQuery("li.hasChildren a").append("<a href='javascript:void(0)' class='expandChild' title='Child item'></a>");
  }

  // jQuery(document).mouseup(e => {
  //     if (!nav.is(e.target) // if the target of the click isn't the container...
  //         && nav.has(e.target).length === 0) // ... nor a descendant of the container
  //     {
  //         nav.removeClass('open');
  //     }
  // });

  jQuery(document).ready(function () {
    jQuery('.open-search-from').click(function () {
      jQuery('section').addClass("open-search");
      jQuery('body').addClass("search-main");
    });
    jQuery('#deleteSearch').click(function () {
      jQuery('section').removeClass("open-search");
      jQuery('body').removeClass("search-main");
    });
  });
  jQuery(window).on('scroll touchmove', function () {
    jQuery('.fade-in').each(function () {
      if (isScrolledIntoView(jQuery(this))) {
        jQuery(this).addClass('animation-started');
      }
    });
  });
  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;
  var navbarHeight = jQuery('header').outerHeight();
  jQuery(window).scroll(function (event) {
    didScroll = true;
  });
  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);
  function hasScrolled() {
    var st = jQuery(window).scrollTop();

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      jQuery('header').removeClass('nav-down').addClass('nav-up');
    } else {
      // Scroll Up
      if (st + jQuery(window).height() < jQuery(document).height()) {
        jQuery('header').removeClass('nav-up').addClass('nav-down');
      }
    }
    lastScrollTop = st;
  }
});
jQuery.event.special.touchstart = {
  setup: function setup(_, ns, handle) {
    this.addEventListener('touchstart', handle, {
      passive: !ns.includes('noPreventDefault')
    });
  }
};
jQuery.event.special.touchmove = {
  setup: function setup(_, ns, handle) {
    this.addEventListener('touchmove', handle, {
      passive: !ns.includes('noPreventDefault')
    });
  }
};